import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import { FormControl, Row } from 'react-bootstrap';
import { IFormField, IReactSelectOption, IReactSelectReturn } from '../../../../../app/models/Application';
import { PalletLoadDetailsForm as PalletLoadDetailsFormClass } from '../../../../../app/models/Pallet';
import { IVehicleBasicInfo, VehicleTypes } from '../../../../../app/models/Vehicle';
import { UserSearch, VehicleSelect } from '../../../../common/inputs';
import { PlacesListNew } from '../../../../common/lists';
import { FieldGroup, Form } from '../../../../common/uiElements';
import TimeField from 'react-simple-timefield';
import FontAwesome from 'react-fontawesome';

interface IProps extends PalletLoadDetailsFormClass {
	isReadOnly?: boolean;
	onChange?: (id: string, value?: string) => void;
	onChangeLoader?: (loader: IReactSelectOption) => void;
	handleShowTaskModal?: () => void;
}

const PalletLoadDetailsForm: React.FC<IProps> = props => {
	// Workaround to handle time field for finish time
	const [focusTimeFieldFinishTime, setFocusTimeFieldFinishTime] = useState(false);

	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value } = e.target;
		props.onChange && props.onChange(id, value);
	} 

	const handleChangeVehicle = (Vehicle?: IVehicleBasicInfo) => {
		props.onChange && props.onChange('fleetNumber', Vehicle?.fleetNumber || '');
	}

	const handleChangeDepot = (depot: IReactSelectReturn) => {
		props.onChange && props.onChange('depotId', depot?.value || '');
	}

	const handleChangeStartTime = (startTime: string) => {
		const loadStartedOn = moment(props.loadStartedOn).format(`YYYY-MM-DDT${startTime}`);
		props.onChange && props.onChange('loadStartedOn', loadStartedOn || '');
	}

	const handleChangeFinishTime = (finishTime: string) => {
		const $loadStartedOn = moment(props.loadStartedOn);
		const $loadFinishedOn = moment(moment(props.loadStartedOn).format(`YYYY-MM-DDT${finishTime}`));
		if ($loadFinishedOn.isBefore($loadStartedOn))
			$loadFinishedOn.add(1, 'day');
		
		const loadFinishedOn = $loadFinishedOn.format(`YYYY-MM-DDTHH:mm`);
		props.onChange && props.onChange('loadFinishedOn', loadFinishedOn || '');
	}

	const detailFields: IFormField[] = [
		/** ID  */
		{ 
			id: 'id',
			label: 'ID', 
			readOnlyValue: props.id,
			sm: 12
		},

		/** DATE  */
		{ 
			id: 'date',
			label: 'Date', 
			readOnlyValue: props.loadStartedOn ? moment(props.loadStartedOn).format('DD/MM/YYYY') : '-', 
			sm: 3, 
			required: true,
		},

		/** DOW */
		{ 
			id: 'dow',
			label: 'Day Of Week', 
			readOnlyValue: props.loadStartedOn ? moment(props.loadStartedOn).format('dddd') : '-', 
			sm: 3
		},
		
		/** RUN NUMBER */
		{ 
			id: 'run',
			label: 'Run Number', 
			readOnlyValue: (
				<p>
					<a onClick={props.handleShowTaskModal} title="See Task / Run Details">
						<FontAwesome name="search" /> <u>{ props.run.name || '-' }</u>
					</a>
				</p>
			),
			sm: 3, 
			required: true
		},

		/** DEPOT */
		{ 
			id: 'depotId',
			label: 'Depot', 
			readOnlyValue: props.depotName,
			sm: 3, 
			required: true, 
			inputComponent: <PlacesListNew id="depotId" type="preStart" value={props.depotId} onChange={handleChangeDepot} />
		},

		/** DOCK NUMBER */
		{ 
			id: 'dockNumber',
			label: 'Dock Number', 
			isReadOnly: props.isReadOnly,
			readOnlyValue: props.dockNumber || '-',
			sm: 3, 
			required: true, 
			inputComponent: <FormControl id="dockNumber" type="number" value={props.dockNumber || ''} onChange={handleChangeInput} />
		},

		/** FLEET NUMBER */
		{ 
			id: 'fleetNumber',
			label: 'Fleet Number', 
			readOnlyValue: props.fleetNumber,
			sm: 3, 
			required: true, 
			inputComponent: <VehicleSelect id="fleetNumber" vehTypeFilter={[VehicleTypes.RIGID, VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE ]} value={props.fleetNumber} onChange={handleChangeVehicle} /> 
		},

		/** LOADER THAT SUBMITTED THE LOAD */
		{ 
			id: 'workerId',
			label: 'Submitted By', 
			readOnlyValue: props.workerName,
			sm: 3, 
			required: true, 
			inputComponent: <UserSearch id="workerId" value={props.workerId} onChange={props.onChangeLoader} />
		},

		/** START TIME */
		{ 
			id: 'loadStartedOn',
			label: 'Start Time', 
			readOnlyValue: props.loadStartedOn ? moment(props.loadStartedOn).format('HH:mm') : '-', 
			sm: 3, 
			required: true,
			inputComponent: (
				<TimeField
					width={4}
					value={props.loadStartedOn ? moment(props.loadStartedOn).format('HH:mm') : ''} 
					input={<FormControl onFocus={(e: any) => e.target.select()} id="loadStartedOn" />} 
					onChange={handleChangeStartTime}
				/>
			)
		},

		/** FINISH TIME */
		{ 
			id: 'loadFinishedOn',
			label: 'Finish Time', 
			readOnlyValue: props.loadFinishedOn ? moment(props.loadFinishedOn).format('HH:mm') : '-', 
			sm: 3, 
			required: false, 
			inputComponent: (
				!props.loadFinishedOn || !focusTimeFieldFinishTime ? (
					<FormControl 
						defaultValue={props.loadFinishedOn ? moment(props.loadFinishedOn).format('HH:mm') : ''} 
						onFocus={() => {
							setFocusTimeFieldFinishTime(true);
							if (!props.loadFinishedOn)
								handleChangeFinishTime('00:00')
						}}
					/>
				) : (
					<TimeField
						width={4}
						value={props.loadFinishedOn ? moment(props.loadFinishedOn).format('HH:mm') : ''} 
						input={<FormControl autoFocus={focusTimeFieldFinishTime} id="loadFinishedOn" />} 
						onChange={handleChangeFinishTime}
					/>
				)
			)
		},

		/** CANCEL DATE */
		{ 
			id: 'loadCancelledOn',
			label: 'Cancelled On', 
			readOnlyValue: props.loadCancelledOn ? moment(props.loadCancelledOn).format('DD/MM/YYYY HH:mm') : '-',
			sm: 3, 
			required: false, 
			show: props.loadCancelledOn ? true : false
		},

		/** CANCEL REASON */
		{ 
			id: 'cancelReason',
			label: 'Cancel Reason', 
			readOnlyValue: props.cancelReason,
			sm: 9, 
			required: false, 
			show: props.cancelReason ? true : false,
			inputComponent: <FormControl id="cancelReason" value={props.cancelReason || ''} onChange={handleChangeInput} />
		}
	]

	const renderFields = (fields: IFormField[]) => (
		fields.map(field => {
			if (field.show === false)
				return undefined;

			return (
				<FieldGroup 
					key={field.label} 
					sm={field.sm} 
					label={field.label} 
					required={field.required} 
					optional={field.inputComponent && !props.isReadOnly && !field.required}
				>
					{
						(field.isReadOnly || props.isReadOnly || !field.inputComponent) ? (
							<p>{ field.readOnlyValue || '-'}</p>
						) : (
							field.inputComponent
						)
					}
				</FieldGroup>
			)
		})
	)

	return (	
		<Form 
			id="pallet-load-form" 
			validations={{
				...detailFields.filter(p => p.required).reduce((obj, item) => Object.assign(obj, { [item.id]: 'required' }), {})
			}}
		>
			<Row>
				{ renderFields(detailFields) }
			</Row>
		</Form>
	)
}

export default PalletLoadDetailsForm;